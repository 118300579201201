export const LayoutType = {
    PlainText: 'PlainText',
    Topic: 'Topic',
    Topic_108_2: 'Topic_108_2',
    WordSection: 'WordSection',
    WordSection_108_2: 'WordSection_108_2',
    Fill: 'Fill',
    Fill_108_2: 'Fill_108_2',
    Image_108_2: 'Image_108_2',
    Simple_108_2: 'Simple_108_2',
};

export const Layout = {
    [LayoutType.PlainText]: 'layout/plainText/plainText.css',
    [LayoutType.Topic]: 'layout/topic/topic.css',
    [LayoutType.Topic_108_2]: 'layout/topic/topic108-2.css',
    [LayoutType.WordSection]: 'layout/wordSection/wordSection.css',
    [LayoutType.WordSection_108_2]: 'layout/wordSection/wordSection108-2.css',
    [LayoutType.Fill]: 'layout/fill/fill.css',
    [LayoutType.Fill_108_2]: 'layout/fill/fill108-2.css',
    [LayoutType.Image_108_2]: 'layout/image/image108-2.css',
    [LayoutType.Simple_108_2]: 'layout/simple/simple108-2.css',
};

export const Script = {
    [LayoutType.Topic]: 'script/topic/topic.js',
    [LayoutType.Topic_108_2]: 'script/topic/topic108-2.js',
    [LayoutType.WordSection]: 'script/wordSection/wordSection.js',
    [LayoutType.WordSection_108_2]: 'script/wordSection/wordSection108-2.js',
    [LayoutType.Fill]: 'script/fill/fill.js',
    [LayoutType.Fill_108_2]: 'script/fill/fill108-2.js',
    [LayoutType.Image_108_2]: 'script/image/image108-2.js',
    [LayoutType.Simple_108_2]: 'script/simple/simple108-2.js',
};

export const LayoutDisplayName = {
    [LayoutType.PlainText]: '純文字型',
    [LayoutType.Topic]: '題目型108上',
    [LayoutType.Topic_108_2]: '題目型108下',
    [LayoutType.WordSection]: '直書型108上',
    [LayoutType.WordSection_108_2]: '直書型108下',
    [LayoutType.Fill]: '填充型108上',
    [LayoutType.Fill_108_2]: '填充型108下',
    [LayoutType.Image_108_2]: '圖片型108下',
    [LayoutType.Simple_108_2]: '簡答型108下',
};
