
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const CheckBox2 = withStyles({
  root: {
    color: 'transparent', // 控制未选中状态的颜色
    '&$checked': {
      color: '#EBB74F', // 控制选中状态的颜色'
      '&> span': {
        '&> svg': {
          border: 'none',
        }
      }
    },
    '&> span': {
      '&> svg': {
        borderRadius: '6px',
        border: '2px solid #FFF2D4',
      },
      '&> path': {
        fill: 'transparent'
      }
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default CheckBox2

