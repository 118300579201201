import React, { useState, useEffect, useCallback, useRef } from "react";
import { useStore, StoreTypes } from "context";
import styles from "./index.module.scss";
import classnames from "classnames";
import { Script } from "components/ExtendedContent/ExtendedContentContainer/layout";
import { scriptLoader } from "util/scriptLoader";
import * as types from "constants/actionTypes";
import { parseChineseOnly } from "util/zhuyin";
import { ScrollPositionType, ScrollLength,ScrollCoordinate } from "constants/scrollPositionType";
import {scrollPositionButtonBehavior} from '../ExtendedContent/ExtendedContentScrollPositionButton'

const SynopsisWindow = ({ synopsisInfo, extendedResourceStyle }) => {
  const listRef = useRef();
  const { list } = synopsisInfo;
  const [{ synopsisList, synopsisTab, fontSize }, extendedContentDispatch] = useStore(
    StoreTypes.extendedContent
  );
  const [{ books }] = useStore(StoreTypes.books);
  const [isChineseSecondary, setIsChineseSecondary] = useState(false);
  const [isChineseElementary, setIsChineseElementary] = useState(false);
  const elementaryHighGradeCheck =
    books[0].display_name.includes("國小國語5") ||
    books[0].display_name.includes("國小國語6");

  const [{ scrollPosition }] = useStore(StoreTypes.extendedContent);
  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    if (synopsisList[synopsisTab]) {
      scriptLoader(Script[extendedResourceStyle]);
    }
  }, [extendedResourceStyle, synopsisList, synopsisTab]);

  useEffect(() => {
    let isChineseSecondary = false,
      isChineseElementary = false;
    synopsisList.forEach((item) => {
      if (item.html.includes("CHINESE SECONDARY")) {
        //  判斷國中國文
        isChineseSecondary = true;
      } else if (item.html.includes("CHINESE ELEMENTARY")) {
        //  判斷國小國文
        isChineseElementary = true;
      }
    });
    setIsChineseSecondary(isChineseSecondary);
    setIsChineseElementary(isChineseElementary);
  }, [synopsisList]);

  const onTabClickHandler = useCallback(
    (index) => {
      extendedContentDispatch({
        type: types.SET_EXTENDED_CONTENT_SYNOPSIS_TAB,
        payload: {
          synopsisTab: index,
        },
      });
    },
    [extendedContentDispatch]
  );

  useEffect(() => {
    listRef.current && listRef.current.scrollTo(listRef.current.scrollWidth, 0);
  }, [synopsisTab]);

  useEffect(() => {
    // 小視窗內容上下頁分段
    // console.log("---C類小視窗---SynopsisWindow", listRef.current);
    switch (scrollPosition) {
      case ScrollPositionType.Next: {
        setLastScrollTop(listRef.current.scrollTop);
        listRef.current.scrollTop += listRef.current.offsetHeight*ScrollLength;
        setCurrentScrollTop(listRef.current.scrollTop);
        break;
      }
      case ScrollPositionType.Prev: {
        setLastScrollTop(listRef.current.scrollTop);
        listRef.current.scrollTop -= listRef.current.offsetHeight*ScrollLength;
        setCurrentScrollTop(listRef.current.scrollTop);
        break;
      }
      default: {
        scrollPositionButtonBehavior({currentScroll: currentScrollTop, lastScroll: lastScrollTop, coordinate: ScrollCoordinate.Y})
        break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition]);

  // 文字排版，如稿紙徹底對齊文字和標點
  useEffect(() => {
    setTimeout(() => {
      const isChineseOnly = listRef.current.querySelector('.chineseOnly');
      if (isChineseOnly) return;
      const ruby = listRef.current.querySelector(':not(.oval) > .az');
      const paragraphIndent = listRef.current.querySelector('.indent');
      const rubyHeight = ruby ? getComputedStyle(ruby).height : null;
      const punctuation = listRef.current.querySelectorAll(':not(.circle) > sign');
      const listBegin = listRef.current.querySelectorAll('.begin');
      const circleListBegin = listRef.current.querySelectorAll('.circle');
      punctuation && punctuation.forEach(element => {
        element.style.height = rubyHeight;
        // element.style.display = 'inline-flex';
        // element.style.textIndent = 'initial';
        // element.style.justifyContent = 'center';
      });
      listBegin && listBegin.forEach(element => {
        if (element.childElementCount === 0) return;
        // element.style.display = 'inline-block';
        element.style.height = rubyHeight;
        element.style.width = rubyHeight;
      });
      circleListBegin && circleListBegin.forEach(element => {
        element.style.height = rubyHeight;
        element.style.width = rubyHeight;
      })
      if (paragraphIndent) paragraphIndent.style.textIndent = `${2 * (rubyHeight.replace('px', ''))}px`;
    }, 100)
  }, [fontSize, synopsisList, synopsisTab, listRef])

  return (
    <div
      className={classnames(styles.synopsisWindow, {
        [styles.wordSection]: extendedResourceStyle === "WordSection",
        [styles.ChineseSecondary]: isChineseSecondary,
        [styles.ChineseElementary]: isChineseElementary,
      }, fontSize)}
    >
      <div className={styles.tab_box}>
        {list &&
          list.map((obj, i) => (
            <div
              className={classnames(styles.tab, {
                [styles.active]: synopsisTab === i,
              })}
              key={i}
              onClick={() => onTabClickHandler(i)}
            >
              {obj.name}
            </div>
          ))}
      </div>
      <div ref={listRef} className={styles.list}>
        {synopsisList[synopsisTab] && (
          <div
            dangerouslySetInnerHTML={{
              __html: elementaryHighGradeCheck ? parseChineseOnly(synopsisList[synopsisTab].html, "pirls") : synopsisList[synopsisTab].html
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SynopsisWindow;
