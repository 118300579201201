import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';

const isMobile = () => {

  try { document.createEvent('TouchEvent'); return true; }

  catch (e) { return false; }

}

export const initState = {
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  isMobile: isMobile(),
  modal: {
    isShowing: false,
    components: null
  },
  musicModal: {
    isShowing: false,
    src: null,
    partialSrc: [],
    subtitles: [],
    isAudioHighlighter: false,
    activeObjectId: '',
    isShowAudioControlBar: true,
    isFollowReadMode: false,
    vtts: [],
    currentTime: 0,
  },
  timerModal: {
    isShowing: false
  },
  stopwatchModal: {
    isShowing: false
  },
  paiquizModal: {
    isShowing: false
  },
  isOnline:true,
  syncNotifyDialog: {
    isShowing: false
  },
};

const globalReducer = (state, action) => {
  switch (action.type) {
    case types.WINDOW_RESIZE:
      const { windowHeight, windowWidth } = action.payload;
      return { ...state, windowHeight, windowWidth }
    case types.OPEN_MODAL:
      return { ...state, modal: { ...state.modal, isShowing: true } }
    case types.CLOSE_MODAL:
      return { ...state, modal: { ...state.modal, isShowing: false } }
    case types.SET_MODAL_COMPONENTS:
      return { ...state, modal: { ...state.modal, components: action.payload.components } }
    case types.OPEN_MUSIC_MODAL:
      return { ...state, musicModal: { ...state.musicModal, isShowing: true, ...action.payload } }
    case types.CLOSE_MUSIC_MODAL:
      return {
        ...state,
        musicModal: {
          ...state.musicModal,
          isShowing: false,
          activeObjectId: '',
          vtts: [],
          isFollowReadMode: false,
          currentTime: ''
        }
      }
    case types.SET_MUSIC_MODAL_PROPERTY:
      return { ...state, musicModal: { ...state.musicModal, ...action.payload } }
    case types.OPEN_TIMER_MODAL:
      return { ...state, timerModal: { isShowing: true } }
    case types.CLOSE_TIMER_MODAL:
      return { ...state, timerModal: { isShowing: false } }
    case types.OPEN_STOPWATCH_MODAL:
      return { ...state, stopwatchModal: { isShowing: true } }
    case types.CLOSE_STOPWATCH_MODAL:
      return { ...state, stopwatchModal: { isShowing: false } }
    case types.OPEN_PAIQUIZ_MODAL:
      return { ...state, paiquizModal: { isShowing: true } }
    case types.CLOSE_PAIQUIZ_MODAL:
      return { ...state, paiquizModal: { isShowing: false } }
    case types.OPEN_SYNCNOTIFY_DIALOG:
      return {...state, syncNotifyDialog: { isShowing: true }}
    case types.CLOSE_SYNCNOTIFY_DIALOG:
      return { ...state, syncNotifyDialog: { isShowing: false } }
    case types.IS_ONLINE_CHECK:
      return { ...state, isOnline: action.isOnline };
    default:
      return state;
  }
};

const useGlobalReducer = () => useEnhanceReducer(globalReducer, initState);

export default useGlobalReducer;
