import { StaticHtmlModalComponent } from './StaticHtmlModalComponent';
import { IFrameModalComponent } from './IFrameModalComponent';
import { VocabularyModalComponent } from './VocabularyModalComponent';
import { VideoModalComponent } from './VideoModalComponent';
import { WordGridModalComponent } from './WordGridModalComponent';
import { WordExampleModalComponent } from './WordExampleModalComponent';
import { WhiteboardModalComponent } from './WhiteboardComponent';
import { SynopsisModalComponent } from './SynopsisComponent';
import { MarkerWithSoundComponent } from './MarkerWithkSoundComponent';
import { TranslationModalComponent } from './Translation';
import { HearingModalComponent } from './HearingComponent';
import { FlashCardModalComponent } from './FlashCardComponent';
import { ListeningStoryModalComponent } from './ListeningStoryComponent';
import { WordGlyphModalComponent } from './WordGlyphModalComponent';
import { WordMeaningModalComponent } from './WordMeaningModalComponent';
import { AboutTheAuthorModalComponent } from './AboutTheAuthorModalComponent';
import { StickyNoteModalComponent } from './StickyNoteModalComponent';
import { LinkModalComponent } from './LinkModalComponent';
import { ReadAloudModalComponent } from './ReadAloudModalComponent';
import { ZhuyinLessonModalComponent } from './ZhuyinLessonModalComponent';
import { ZhuyinImageModalComponent } from './ZhuyinImageModalComponent';
import { ZhuyinWordModalComponent } from './ZhuyinWordModalComponent';
import { VolumeOneWordsModalComponent } from './VolumeOneWordsComponent';

export const ModalComponentType = {
  StaticHtml: 'staticHtml',
  IFrame: 'iframe',
  Vocabulary: 'vocabulary',
  Video: 'video',
  WordGrid: 'wordGrid',
  WordExample: 'wordExample',
  VolumeOneWords: 'volumeOneWords',
  Whiteboard: 'whiteboard',
  NumberPicker: 'numberPicker',
  Synopsis: 'Synopsis',
  MarkerWithSound: 'MarkerWithSound',
  Translation: 'translation',
  Hearing: 'hearing',
  FlashCard: 'flashCard',
  Listening: 'listening',
  WordGlyph: 'wordGlyph',
  WordMeaning: 'wordMeaning',
  AboutTheAuthor: 'aboutTheAuthor',
  StickyNote: 'stickyNote',
  Link: 'link',
  ReadAloud: 'readAloud',
  ZhuyinLesson: 'zhuyinLesson',
  ZhuyinImage: 'zhuyinImage',
  ZhuyinWord: 'zhuyinWord',
};

export const ModalComponentGenerator = {
  generate: (type, payload) => {
    let component = null;
    switch (type) {
      case ModalComponentType.StaticHtml:
        component = StaticHtmlModalComponent(payload);
        break;
      case ModalComponentType.IFrame:
        component = IFrameModalComponent(payload);
        break;
      case ModalComponentType.Vocabulary:
        component = VocabularyModalComponent(payload);
        break;
      case ModalComponentType.Video:
        component = VideoModalComponent(payload);
        break;
      case ModalComponentType.WordGrid:
        component = WordGridModalComponent(payload);
        break;
      case ModalComponentType.WordExample:
        component = WordExampleModalComponent(payload);
        break;
      case ModalComponentType.Whiteboard:
        component = WhiteboardModalComponent(payload);
        break;
      case ModalComponentType.Synopsis:
        component = SynopsisModalComponent(payload);
        break;
      case ModalComponentType.MarkerWithSound:
        component = MarkerWithSoundComponent(payload);
        break;
      case ModalComponentType.Translation:
        component = TranslationModalComponent(payload);
        break;
      case ModalComponentType.Hearing:
        component = HearingModalComponent(payload);
        break;
      case ModalComponentType.FlashCard:
        component = FlashCardModalComponent(payload);
        break;
      case ModalComponentType.Listening:
        component = ListeningStoryModalComponent(payload);
        break;
      case ModalComponentType.WordGlyph:
        component = WordGlyphModalComponent(payload);
        break;
      case ModalComponentType.WordMeaning:
        component = WordMeaningModalComponent(payload);
        break;
      case ModalComponentType.AboutTheAuthor:
        component = AboutTheAuthorModalComponent(payload);
        break;
      case ModalComponentType.StickyNote:
        component = StickyNoteModalComponent(payload);
        break;
      case ModalComponentType.Link:
        component = LinkModalComponent(payload);
        break;
      case ModalComponentType.ReadAloud:
        component = ReadAloudModalComponent(payload);
        break;
      case ModalComponentType.ZhuyinLesson:
        component = ZhuyinLessonModalComponent(payload);
        break;
      case ModalComponentType.ZhuyinImage:
        component = ZhuyinImageModalComponent(payload);
        break;
      case ModalComponentType.ZhuyinWord:
        component = ZhuyinWordModalComponent(payload);
        break;
      case ModalComponentType.VolumeOneWords:
        component = VolumeOneWordsModalComponent(payload);
        break;
      default:
        throw new Error(`ModalComponentType ${type} is not supported`);
    }
    return component;
  }
};
