import React,{ useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ToolBarDirection } from 'constants/ReaderTools';
import ToolSectionTitle from 'components/Tools/ToolSectionTitle';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';

const position = [
  { label:'下方', value: ToolBarDirection.BOTTOM },
  { label:'上方', value: ToolBarDirection.TOP },
  { label:'左方', value: ToolBarDirection.LEFT },
  { label:'右方', value: ToolBarDirection.RIGHT }
];


const ToolsPosition = () => {
  const [{ readerToolDirection }] = useStore(StoreTypes.reader);
  const changeHandler = useCallback((direction)=>{
      EventBus.emit({
        event: ReaderToolsEvent.ChangeReaderToolDirectionEvent,
        payload: direction
    })
  },[])


  return (
    <div className={styles.position_box}>
      <ToolSectionTitle label="工具列位置" />
      <div className={styles.position}>
        {
          position.map(item=> 
            <div className={styles.option} key={item.value}>
              <label>
                <input 
                  type='radio' 
                  name="positionSelect" 
                  value={item.value} 
                  checked={readerToolDirection === item.value} 
                  onChange={(e)=>changeHandler(e.target.value)}
                />
                <span className={styles.text}>{item.label}</span>
              </label>
            </div>
          )
        }
      </div>
    </div>
);
}
export default ToolsPosition;
