import { useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { ActivityEvent } from 'events/EventTypes';
import { useEvent } from 'events/EventBus';
import { API } from 'api';
import { useSetActivityInfo } from 'customHooks/activity';
import { AnnotationType } from 'constants/annotationTypes';
import { useReadAnnotations, useCreateAnnotation } from 'customHooks/db';
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};

export const useInitializeActivity = () => {
    const reducers = useStore();
    const [{ token, userId }, userDispatch] = reducers[StoreTypes.user];
    const [, annotationDispatch] = reducers[StoreTypes.annotation];
    const setActivityInfo = useSetActivityInfo();
    const { readAnnotationById } = useReadAnnotations();
    const createAnnotation = useCreateAnnotation();

    // init firestore
    useEffect(() => {
        let admin;
        if (firebase.apps.length <= 0) {
            admin = firebase.initializeApp(config, 'other');
        } else {
            admin = firebase.apps[0];
        }
        annotationDispatch({ type: types.SET_FIRESTORE, firestore: admin.firestore() });
    }, [annotationDispatch]);

    const creatActivityHandler = useCallback(({ bookId, activityId, name, callback }) => {
        (async () => {
            const obj = {
                token:{jwt:token},
                bookId,
                activityId,
                name
            };
            let err = null;
            const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/launchActivity`, obj);
            if (response.status === 'success') {
                const activity = response.content;
                setActivityInfo({
                    activityId: activity.id,
                    name: activity.name,
                    attendeeNumber: 'admin',
                    activityTarget: activity.syncTarget,
                    ownerId: userId
                });
            } else {
                err = {
                    status: 'failed'
                };
            }
            callback && callback(err);
        })();
    }, [setActivityInfo, token, userId]);

    const enterActivityHandler = useCallback(async ({ activityId, callback }) => {
        (async () => {
            const obj = {
                token:{jwt:token},
                activityId
            };

            let err = null;
            const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/getActivity`, obj);
            if (response.status === 'success') {
                const activity = response.content;
                setActivityInfo({
                    activityId: activity.id,
                    name: activity.name,
                    attendeeNumber: 'admin',
                    activityTarget: activity.syncTarget,
                    ownerId: activity.teacherId
                });
            } else {
                err = {
                    status: 'failed'
                };
            }
            // TODO: annotation sync
            callback && callback(err);
        })();
    }, [setActivityInfo, token]);

    const joinActivityHandler = useCallback(async ({ teacherId, activityId, attendeeNumber, callback }) => {
        (async () => {
            const obj = {
                teacherId,
                activityId,
                attendeeId: userId,
                attendeeNo: attendeeNumber
            };
            let err = null;
            const joinResponse = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/joinActivity`, obj)
            if (joinResponse.status === 'success') {
                const activity = joinResponse.content.activity;
                userDispatch({
                    type: types.UPDATE_USER_INFO,
                    userId: joinResponse.content.attendee.id
                })
                setActivityInfo({
                    activityId: activity.id,
                    name: activity.name,
                    attendeeNumber: attendeeNumber,
                    activityTarget: activity.syncTarget,
                    ownerId: activity.teacherId
                });
                const annotation = await readAnnotationById({ id: activity.id });
                if (!annotation) {
                    await createAnnotation({
                        id: activity.id,
                        bookId: activity.bookId,
                        name: activity.name,
                        type: AnnotationType.ACTIVITY,
                        pageIndex: 0,
                        annotations: []
                    });
                }
            } else {
                err = {
                    status: 'failed'
                };
            }
            callback && callback(err);
        })();
    }, [userId, userDispatch, setActivityInfo, readAnnotationById, createAnnotation]);

    useEvent({ event: ActivityEvent.CreatActivityEvent }, creatActivityHandler);
    useEvent({ event: ActivityEvent.EnterActivityEvent }, enterActivityHandler);
    useEvent({ event: ActivityEvent.JoinActivityEvent }, joinActivityHandler);
};
