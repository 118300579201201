import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import ColorPalette from 'components/common/ColorPalette';
import {DefaultColors} from 'constants/colors';

const colorArr = [
    'transparent',
    ...DefaultColors
];

const ObjectColorSelector = ({ title, propertyName, target: object }) => {
    const selectColorHandler = useCallback(value => {
        if (object) {
            EventBus.emit({
                event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
                payload: { object, property: propertyName, value }
            });
        }
    }, [object, propertyName]);

    return (
        <div className={styles.pathTools}>
            <h4>{title}</h4>
            <ColorPalette colors={colorArr} onChange={value => selectColorHandler(value)} />
        </div>
    );
};
export default ObjectColorSelector;
