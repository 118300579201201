import React from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import { useStore, StoreTypes } from 'context';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import * as types from 'constants/actionTypes';

import { withTranslation } from 'react-i18next';

import { gaEventReaderToolsClick } from 'components/AppService/GAService'
import { ReaderToolTypeDisplayMap } from 'constants/contentType';
import { CategoryType } from 'constants/gaEventType';



const ReaderToolBookshelf = ({ t, type }) => {
    const router = useStore(StoreTypes.router);
    const [,globalDispatch] = useStore(StoreTypes.global);
    const notNotify = localStorage.getItem('notifySync');
    const clickHandler = () => {
        gaEventReaderToolsClick({
            category: CategoryType.ReaderTool,
            action: ReaderToolType.Bookshelf,
            label: ReaderToolType.Bookshelf
          });
          
        EventBus.emit({
            event: ReaderToolsEvent.SetReaderToolTypeEvent,
            payload: {
                readerToolType: ReaderToolType.Drag
            }
        });

        EventBus.emit({
            event: ReaderToolsEvent.ClickDragEvent
        });
        EventBus.emit({
            event: ReaderToolsEvent.ClickReaderToolBoxEvent,
            payload: {
              isToolboxShow: false
            }
          })
        if (window.electron && notNotify === null) {
            globalDispatch({ type: types.OPEN_SYNCNOTIFY_DIALOG });
        } else {
            router.history.push('/bookshelf');
        }
    }
    return (
        <Button key={type} onClick={clickHandler}>
            <Icon name={toolsEventMap[type].icon} />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(ReaderToolBookshelf);
