import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import BoxModal from 'components/PaiQuiz/BoxModal'
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { QRCodeSVG } from 'qrcode.react';
import immediatePaiPic from 'components/PaiQuiz/immediatePai.png';
import oneLinkPaiPic from 'components/PaiQuiz/oneLinkPai.png';
import oneLinkClassPic from 'components/PaiQuiz/oneLinkClass.png';
import oneLinkClassChoosePic from 'components/PaiQuiz/oneLinkClassChoose.png';
import basic from 'components/PaiQuiz/oneStar.png';
import advance from 'components/PaiQuiz/twoStar.png';
import challenge from 'components/PaiQuiz/threeStar.png';
import chooseIcon from 'components/PaiQuiz/chooseQuizIcon.png';
import returnArrow from 'components/PaiQuiz/re-chooseQuiz.svg';
import copyIcon from 'components/PaiQuiz/copy.svg';
import lockIcon from 'components/PaiQuiz/noAccessLock.svg';
import customerServiceQRCode from 'components/PaiQuiz/paiQuizCustomerServiceQR.svg';
import { LottiePlayer } from '../LottieLoading/lottiePlayer';

const PaiQuizContent = ({ paiStatus, getImmediatePaiExam, immediatePaiResult, getOneLinkClass, oneLinkClass, choosenClass, chooseClass, paiquizIDs, chooseQuiz, choosenQuiz, hasOnlineExam, isLoading }) => {
  const examLink = useRef(null);
  const copyLink = () => {
    navigator.clipboard.writeText(examLink.current.textContent)
  }

  const [{ userId }] = useStore(StoreTypes.user);

  const quizText = (index) => {
    if (index === 0) return '基礎';
    if (index === 1) return '進階';
    if (index === 2) return '挑戰';
  }
  const quizIcon = (index) => {
    if (index === 0) return basic;
    if (index === 1) return advance;
    if (index === 2) return challenge;
  }

  const [choosenPaiway, setChoosenPaiway] = useState('');

  switch (paiStatus) {
    case 'immediate':
      return (
        <div className={styles.immediateContent}>
          <div className={styles.qrArea}>
            <div className={styles.qrCode}>
              <QRCodeSVG value={`${process.env.REACT_APP_ONE_EXAM_RESULT_URL}/quiz/${immediatePaiResult.quizCode}`} size={196} />
            </div>
            <div>
              <h3>測驗請掃描QR code或複製連結</h3>
              <h2 class={styles.qrText}>
                <span>測驗名稱：</span>
                <span>{immediatePaiResult.examName}</span>
              </h2>
              <h2 class={styles.qrText}>
                <span>結束時間：</span>
                <span>{immediatePaiResult.endAt}</span>
              </h2>
            </div>
          </div>
          <div className={styles.linkArea}>
            <span ref={examLink}>{`${process.env.REACT_APP_ONE_EXAM_RESULT_URL}/quiz/${immediatePaiResult.quizCode}`}</span>
            <button type="button" onClick={copyLink}><img src={copyIcon} alt='複製' /></button>
          </div>
        </div>
      )
    case 'oneLink':
      return (
        isLoading ? <div className={`${styles.oneLinkContent} ${styles.loading}`}><LottiePlayer /></div> :
          <div className={classnames(styles.oneLinkContent, { [styles.freeze]: !hasOnlineExam })}>
            <div className={classnames(styles.noAccess, { [styles.show]: !hasOnlineExam })}>
            <img src={customerServiceQRCode} alt='數位產品客服' />
              <div className={styles.noAccessText}>
                <img src={lockIcon} alt='解鎖權限' />
                <span>您尚未開通權限請洽南一數位產品客服</span>
              </div>
              <a href='https://lin.ee/FdL4cmD' target='_blank' rel='noopener noreferrer'>https://lin.ee/FdL4cmD</a>
            </div>
            {oneLinkClass.map((classroom) => {
              return (
                <div className={styles.class} key={classroom.id} onClick={() => chooseClass(classroom.id, classroom.organizationId, userId)}>
                  <img src={classroom.id === choosenClass.id ? oneLinkClassChoosePic : oneLinkClassPic} alt="班級" />
                  <p>{classroom.name}</p>
                </div>
              )
            })}
          </div>
      )
    case 'chooseQuiz':
      return (
        <div className={styles.chooseQuizContent}>
          {isLoading ? <LottiePlayer /> :
            paiquizIDs.map((id, index) => {
              return (
                <div className={classnames(styles.chooseQuiz, { [styles.choose]: id === choosenQuiz.id })} key={index} onClick={() => chooseQuiz(id, quizText(index))}>
                  <img className={classnames(styles.chooseIcon, { [styles.choose]: id === choosenQuiz.id })} src={chooseIcon} alt="choose" />
                  <img className={styles.quizIcon} src={quizIcon(index)} alt="測驗卷" />
                  <span>{quizText(index)}</span>
                </div>
              )
            })
          }
        </div>)
    case 'unLogin':
      return (
        <div style={{
          color: 'white',
          width: '500px',
          height: '185px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
          fontWeight: '500',
        }}>請登入以使用派測驗功能</div>
      )
    case 'original':
    default:
      return (
        isLoading ?
          <div className={styles.originalContent
          } >
            <LottiePlayer />
          </div > :
          <div className={styles.originalContent}>
            <div className={classnames(styles.immediatePai, { [styles.choose]: choosenPaiway === 'immediate' })} onClick={() => {
              setChoosenPaiway('immediate');
              setTimeout(() => { getImmediatePaiExam() }, 1000)
            }
            }>
              <img className={classnames(styles.chooseIcon, { [styles.choose]: choosenPaiway === 'immediate' })} src={chooseIcon} alt="choose" />
              <div>
                <h2>立即派</h2>
                <img src={immediatePaiPic} alt="立即派" />
              </div>
              <p>
                免建班<br />掃描QRcode立即測驗
              </p>
            </div>
            <div className={classnames(styles.oneLinkPai, { [styles.choose]: choosenPaiway === 'oneLink' })} onClick={() => {
              setChoosenPaiway('oneLink');
              setTimeout(() => { getOneLinkClass() }, 1000)
            }
            }>
              <img className={classnames(styles.chooseIcon, { [styles.choose]: choosenPaiway === 'oneLink' })} src={chooseIcon} alt="choose" />
              <div>
                <h2>OneLink派</h2>
                <img src={oneLinkPaiPic} alt="oneLink派" />
              </div>
              <p>
                輕鬆指派班級作業<br />測驗結果完整留存
              </p>
            </div>
          </div>
      )
  }
}

const PaiQuizBottom = ({ paiStatus, immediatePaiResult, choosenClass, getOneLinkExam, setPaiStatus, hasOnlineExam, isLoading, token }) => {
  switch (paiStatus) {
    case 'immediate':
      return (
        <div className={styles.immediateBottom}>
          {
            window.electron ? 
            <button className={`${styles.resultLink} ${styles.forElectron}`} onClick={() => {
              const data = {
                src: `${process.env.REACT_APP_ONE_EXAM_RESULT_URL}/quiz/review/${immediatePaiResult.schoolYear}/${immediatePaiResult.quizCode}/${immediatePaiResult.quizId}`,
                token
              }
              window.electron && window.electron.ipcRenderer.send("openImmediatePai", data);
            }}>查看作答記錄</button> : 
            <a href={`${process.env.REACT_APP_ONE_EXAM_RESULT_URL}/quiz/review/${immediatePaiResult.schoolYear}/${immediatePaiResult.quizCode}/${immediatePaiResult.quizId}`} className={styles.resultLink} target="_blank" rel="noopener noreferrer">查看作答記錄</a>
          }
          {/* <a href={`${process.env.REACT_APP_ONE_EXAM_RESULT_URL}/quiz/review/${immediatePaiResult.schoolYear}/${immediatePaiResult.quizCode}/${immediatePaiResult.quizId}`} className={styles.resultLink} target="_blank" rel="noopener noreferrer">查看作答記錄</a> */}
        </div>
      )
    case 'oneLink':
      return (
        !hasOnlineExam ?
          <div className={styles.noAccessBottom}>
            <button type="button" onClick={() => setPaiStatus('original')}>返回上一頁<img src={returnArrow} alt='返回' /></button>
          </div>
          : <div className={styles.oneLinkBottom}>
            <button type="button" disabled={choosenClass.id === ''} className={classnames({ [styles.disable]: choosenClass.id === '' })} onClick={() => getOneLinkExam(choosenClass)}>確定派送</button>
          </div>
      )

    case 'original':
      return (
        !isLoading && <div className={styles.originalBottom}>
          <button type="button" onClick={() => setPaiStatus('chooseQuiz')}>重新選卷<img src={returnArrow} alt='返回' /></button>
        </div>
      )
    default:
      return null
  }
}

const PaiQuiz = () => {
  const [paiStatus, setPaiStatus] = useState('chooseQuiz');
  const [needConfirmClose, setNeedConfirmClose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // 關閉彈窗及確認關閉
  const [_, globalDispatch] = useStore(StoreTypes.global);
  const onClose = useCallback(() => {
    if (paiStatus === 'immediate' && !needConfirmClose) {
      setNeedConfirmClose(true);
    } else {
      globalDispatch({ type: types.CLOSE_PAIQUIZ_MODAL });
    }
  }, [globalDispatch, needConfirmClose, paiStatus]);
  const goBack = () => {
    setNeedConfirmClose(false);
  }

  // 選取考卷
  const [{ paiquizID }, readerDispatch] = useStore(StoreTypes.reader);
  const [paiquizIDs, setPaiQuizIDs] = useState([]);
  const [choosenQuiz, setChoosenQuiz] = useState({id: '', type: ''});
  useEffect(() => {
    if (paiquizID === 'unLogin') {
      setPaiStatus('unLogin');
      readerDispatch({type: types.SET_PAIQUIZ_ID, paiquizID: ''});
      return
    }
    const newpaiquizID = paiquizID.trim();
    const arr = newpaiquizID.split(',');
    setPaiQuizIDs(arr);
  }, [paiquizID, readerDispatch])
  const chooseQuiz = (id, type) => {
    setChoosenQuiz({id, type});
    setTimeout(() => { setPaiStatus('original'); setTitle('請選擇派送方式'); }, 500);
  }

  // 取得立即派測驗
  const [{ books }] = useStore(StoreTypes.books);
  const url = window.location.href.split("?")[0];
  let bookId = url.split("/")[url.split("/").length - 1];
  const book = books.find(book => book.bookId === bookId);
  const [{ token }] = useStore(StoreTypes.user);
  const [immediatePaiResult, setImmediatePaiResult] = useState({});
  const [title, setTitle] = useState('請選擇派送卷');

  // const generateExamName = () => {
  //   const today = new Date();
  //   const day = ("0" + (today.getMonth() + 1)).slice(-2) + ("0" + today.getDate()).slice(-2);
  //   return `${day} ${book.display_name.slice(book.display_name.indexOf("國"), book.display_name.indexOf("國") + 4)}測驗 ${choosenQuiz.type}`
  // }

  const generateExamEndTime = () => {
    const now = Date.now();
    const endAt = new Date(now + 35 * 60 * 1000).toISOString();
    return endAt;
  }
  const localizeEndAt = (endAt) => {
    const endTime = new Date(endAt);
    const month = ('0' + (endTime.getMonth() + 1)).slice(-2);
    const day = ('0' + endTime.getDate()).slice(-2);
    const hour = endTime.getHours();
    const minutes = ('0' + endTime.getMinutes()).slice(-2);
    return `${month}/${day} ${hour}:${minutes}`
  }
  const getImmediatePaiExam = async () => {
    setTitle('出題中');
    setIsLoading(true);
    const data = {
      'examName': '',
      'paperId': choosenQuiz.id,
      'examPeriod': 30,
      'service': 'OneBook',
      'endAt': generateExamEndTime(),
      'paperType': 'exam',
      'isAnnounced': true
    }
    const result = await fetch(`${process.env.REACT_APP_ONE_EXAM_DOMAIN}/api/quiz`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json()).catch(err => console.log("Create immediate quiz Error", err));
    if (result.status !== 'success') {
      console.log("get immediate quiz", result);
      setIsLoading(false);
      return
    }
    const immediatePaiData = {
      examName: result.content.attribute.examName,
      endAt: localizeEndAt(result.content.endAt),
      quizCode: result.content.quizCode,
      quizId: result.content.quizId,
      schoolYear: result.content.schoolYear
    }
    setImmediatePaiResult(immediatePaiData);
    setIsLoading(false);
  }
  useEffect(() => {
    if (Object.keys(immediatePaiResult).length !== 0) {
      setPaiStatus('immediate');
      setTitle('');
    }
  }, [immediatePaiResult])

  // 取得 oneLink 班級
  const [oneLinkClass, setOneLinkClass] = useState([]);
  const [hasOnlineExam, setHasOnlineExam] = useState(true);
  const getOneLinkClass = async () => {
    setTitle('查詢班級中');
    setIsLoading(true);
    const classroomResult = await fetch(`${process.env.REACT_APP_ONE_LINK_DOMAIN}/groups`, {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    })
      .then(res => res.json())
      .catch(err => console.log(err));
    if (classroomResult.status !== 'success') {
      console.log("get onelink quiz", classroomResult);
      setIsLoading(false);
      return
    }
    if (classroomResult.data.length === 0) {
      setPaiStatus('oneLink');
      setHasOnlineExam(false);
    } else {
      setOneLinkClass(classroomResult.data);
      // setHasOnlineExam(classroomResult.data.every(item => item.navigationFunctions.includes('onlinExam')));
      setHasOnlineExam(classroomResult.data[0].navigationFunctions.includes('onlineExam'));
    }
    setTitle('派測驗');
    setIsLoading(false);
  }
  useEffect(() => {
    if (oneLinkClass.length !== 0) {
      setPaiStatus('oneLink');
    }
  }, [oneLinkClass])

  // 選取班級
  const [choosenClass, setChoosenClass] = useState({
    id: '',
    organizationId: '',
    userId: ''
  });
  const chooseClass = (id, organizationId, userId) => {
    setChoosenClass({
      id,
      organizationId,
      userId
    });
  }

  // 派測驗
  const [goToOneLink, setGoToOneLink] = useState(false);
  const [goToOneLinkLink, setGoToOneLinkLink] = useState('https://onelink.oneclass.com.tw/');
  const getOneLinkExam = async (choosenClass) => {
    setTitle('派卷中');
    setIsLoading(true);
    setChoosenClass({
      id: '',
      organizationId: '',
      userId: ''
    })
    const data = {
      'name': '',
      'paperId': choosenQuiz.id,
      'eduSubjectName': book.display_name.slice(book.display_name.indexOf("國"), book.display_name.indexOf("國") + 4),
      'period': 30,
      // 'startAt': Date.now(),
      // 'endAt': Date.now() + 30 * 60 * 1000,
      'markers': [choosenClass.userId]
    }
    const result = await fetch(`${process.env.REACT_APP_ONE_LINK_DOMAIN}/organizations/${choosenClass.organizationId}/groups/${choosenClass.id}/exams`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .catch(err => console.log(err))

    if (result.status !== 'success') {
      console.log('get onelink exam', result);
      if (result.error.errorCode === 20005) {
        setHasOnlineExam(false);
        setPaiStatus('oneLink');
        setIsLoading(false);
      }
      return
    } else {
      setGoToOneLinkLink(`${process.env.REACT_APP_ONE_LINK_RESULT_URL}/home/${choosenClass.organizationId}/class/${choosenClass.id}/onlineExam`);
      setIsLoading(false);
      setGoToOneLink(true);
    }

  }

  return (
    <BoxModal
      onCloseButtonClick={onClose}
      goBack={goBack}
      title={title}
      content={
        <PaiQuizContent
          paiStatus={paiStatus}
          getImmediatePaiExam={getImmediatePaiExam}
          immediatePaiResult={immediatePaiResult}
          getOneLinkClass={getOneLinkClass}
          oneLinkClass={oneLinkClass}
          choosenClass={choosenClass}
          chooseClass={chooseClass}
          paiquizIDs={paiquizIDs}
          chooseQuiz={chooseQuiz}
          choosenQuiz={choosenQuiz}
          hasOnlineExam={hasOnlineExam}
          isLoading={isLoading}
        />
      }
      bottom={
        <PaiQuizBottom
          paiStatus={paiStatus}
          setPaiStatus={setPaiStatus}
          immediatePaiResult={immediatePaiResult}
          choosenClass={choosenClass}
          getOneLinkExam={getOneLinkExam}
          hasOnlineExam={hasOnlineExam}
          isLoading={isLoading}
          token={token}
        />
      }
      needConfirmClose={needConfirmClose} goToOneLink={goToOneLink} goToOneLinkLink={goToOneLinkLink}>
    </BoxModal>
  )
}

export default PaiQuiz;
