export const InteractiveObjectContentType = {
  ExternalWindow: 'ExternalWindow',
  InternalWindow: 'InternalWindow',
  InternalHtmlWindow: 'InternalHtmlWindow',
  ToggleDisplay: 'ToggleDisplay',
  ControlDisplay: 'ControlDisplay',
  Stage: 'Stage',
  ControlStage: 'ControlStage',
  Image: 'Image',
  ControlImage: 'ControlImage',
  GoPage: 'GoPage',
  Video: 'Video',
  Audio: 'Audio',
  AreaZoom: 'AreaZoom',
  Vocabulary: 'Vocabulary',
  WordGrid: 'WordGrid',
  Submenu: 'Submenu',
  WordExample: 'WordExample',
  Translation: 'Translation',
  NumberPicker: 'NumberPicker',
  Synopsis: 'Synopsis',
  Whiteboard: 'Whiteboard',
  Hearing: 'Hearing',
  FlashCard: 'FlashCard',
  Listening: 'Listening',
  WordGlyph: 'WordGlyph',
  WordMeaning: 'WordMeaning',
  AboutTheAuthor: 'AboutTheAuthor',
  ReadAloud: 'ReadAloud',
  ZhuyinLesson: 'ZhuyinLesson',
  ZhuyinImage: 'ZhuyinImage',
  ZhuyinWord: 'ZhuyinWord',
  VolumeOneWords: 'VolumeOneWords',
  MathTools: 'MathTools',
  Timer: 'Timer',
  Stopwatch: 'Stopwatch',
  MeasureTools: 'MeasureTools',
  PaiQuiz: 'PaiQuiz'
};

export const InteractiveObjectControlStageType = {
  Previous: 'Previous',
  Next: 'Next',
  All: 'All'
};

export const MathToolsType = {
  Compass: "Compass",
  Ruler: "Ruler",
}