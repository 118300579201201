import React, { useCallback, useEffect } from 'react';
import { CanvasLockType } from 'constants/lockTypes';
import Input from 'components/common/Input';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import useSetState from 'customHooks/setState';
import styles from './index.module.scss';

import CheckBox2 from 'components/common/CheckBox2';

const LockTools = ({ target: object }) => {
  const [{ lockMovementX, lockMovementY, hasControls }, setState] = useSetState(
    {
      lockMovementX: object[CanvasLockType.LockMovementX],
      lockMovementY: object[CanvasLockType.LockMovementY],
      hasControls: object[CanvasLockType.HasControls]
    }
  );

  const setLock = useCallback(
    (property, value) => {
      EventBus.emit({
        event: CanvasEvent.LockCanvasObjectEvent,
        payload: { object, property, value }
      });
    },
    [object]
  );

  useEffect(() => {
    const { lockMovementX, lockMovementY, hasControls } = object;
    setState({
      lockMovementX,
      lockMovementY,
      hasControls
    });
  }, [
    object,
    object.lockMovementX,
    object.lockMovementY,
    object.hasControls,
    setState
  ]);

  return (
    <div className={styles.lockTools}>
      <h4>鎖定</h4>
      <div className={styles.lockToolsInput}>
        <div>
          <label>
            縮放
          </label>
          <CheckBox2
            className={styles.checkbox}
            type="checkbox"
            label="縮放"
            checked={!hasControls ? 'checked' : ''}
            onChange={e => {
              setLock(CanvasLockType.HasControls, !e.target.checked);
            }}
          />
        </div>
        <div>
          <label>
            水平
          </label>
          <CheckBox2
            className={styles.checkbox}
            type="checkbox"
            label="水平"
            checked={object[CanvasLockType.LockMovementX] ? 'checked' : ''}
            onChange={e =>
              setLock(CanvasLockType.LockMovementX, e.target.checked)
            }
          />
        </div>
        <div>
          <label>
            垂直
          </label>
          <CheckBox2
            className={styles.checkbox}
            type="checkbox"
            label="垂直"
            checked={lockMovementY ? 'checked' : ''}
            onChange={e =>
              setLock(CanvasLockType.LockMovementY, e.target.checked)
            }
          />
        </div>

        <div>
          <label>
            全選
          </label>
          <CheckBox2
            className={styles.checkbox}
            type="checkbox"
            label="全選"
            checked={
              lockMovementX && lockMovementY && !hasControls ? 'checked' : ''
            }
            onChange={e => {
              setLock(CanvasLockType.LockMovementX, e.target.checked);
              setLock(CanvasLockType.LockMovementY, e.target.checked);
              setLock(CanvasLockType.HasControls, !e.target.checked);
            }}
          />
        </div>

      </div>
    </div>
  );
};

export default LockTools;
