import React from 'react';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import { ReaderToolType } from 'constants/ReaderTools';
import { withTranslation } from 'react-i18next';

export const active = { backgroundColor: "#aaa", color: "#fff" };

const ActivatableReaderToolComponent = ({ t, currentReaderToolType, type, additionalClickHandler }) => {
    const doubleClickToolPanel = {
        Painting: ReaderToolType.Painting,
        Highlighter: ReaderToolType.Painting
    }
    return (
        <Button
            key={type}
            onClick={(e) => {
                toolsEventMap[type].clickHandler();
                if (doubleClickToolPanel[type]) {
                    e.detail === 2 && additionalClickHandler && additionalClickHandler();
                    return
                }
                additionalClickHandler && additionalClickHandler();
            }}
            title={t(toolsEventMap[type].title)}
            inlineStyle={currentReaderToolType === type && active}
        >
            <Icon name={toolsEventMap[type].icon} />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(ActivatableReaderToolComponent);
