import ReactGA from "react-ga";
import { useLocationChange } from "customHooks/locationChange";
import { CategoryTypeWording } from "constants/gaEventType";
import {
  ContentTypeDisplayMap,
  ReaderToolTypeDisplayMap,
  SyncAnnotationType,
  QrcodeLoginType
} from "constants/contentType";

const gaPageView = (location) => {
  ReactGA.pageview(`${location.pathname}${location.search}`);
};

export const useGAPageView = () => useLocationChange(gaPageView);

export const gaEventInteractiveObjectClick = ({ category, action, label }) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: ContentTypeDisplayMap[action],
    label: label || "",
  });
};

export const gaEventReaderToolsClick = ({ category, action, label }) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: ReaderToolTypeDisplayMap[action] || action,
    label: label || "",
  });
};

export const gaEventQrcodeLogin = ({ category, action, label }) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: QrcodeLoginType[action],
    label: label || ""
  })
}

export const gaEventAnnotationSynchronizeClick = ({ 
  category,
  action,
  label
 }) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: SyncAnnotationType[action],
    label
  })
}

export const gaEventNextPrevPage = ({category, action, label}) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: ReaderToolTypeDisplayMap[action] || action,
    label: label || "",
  })
}
