import React, { useCallback } from 'react';
import ToolSectionTitle from 'components/Tools/ToolSectionTitle';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import styles from './index.module.scss';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';

const fullWidthType = {
    svg: "svg",
    png: "png",
}

const inputInfo = [
    { label: fullWidthType.svg, value: false },
    { label: fullWidthType.png, value: true }
];

const PageTypeSwitch = () => {
    const [{ pageType },readerDispatch] = useStore(StoreTypes.reader);
    const selectHandler = useCallback((value) => {
        EventBus.emit({
            event: ReaderEvent.SetPageType,
            payload: { pageType: value }
          });
    }, [])

    return (
        <div className={styles.fullWidth}>
            <ToolSectionTitle label="頁面格式切換" />
            <div className={styles.labelGroup}>
                {
                    inputInfo.map(item =>
                        <label key={item.label}>
                            <input
                                type='radio'
                                name="pageTypeSwitch"
                                value={item.label}
                                checked={pageType === item.label}
                                onChange={(e) => selectHandler(e.target.value)}
                            />
                            <span >{item.label}</span>
                        </label>
                    )
                }
            </div>
        </div>
    )
};


export default PageTypeSwitch;
