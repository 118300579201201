import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import Input from 'components/common/Input';
import useSetState from 'customHooks/setState';

const BasePosition = ({ target: object }) => {
  const [objectState, setObject] = useSetState({ ...object });
  const submitHandler = useCallback(property => e => {

    let { value } = e.target;
    let key = e.which || e.keyCode;
    if (key && key !== 13) return;
    value = (value && parseFloat(value)) || 0;
    EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property, value } })
  }, [object]);

  const changeHandler = property => e => {
    let { value } = e.target;
    value = (value && parseFloat(value)) || 0;
    setObject({ [property]: value });
  }

  return object && (<div className={styles.basePosition}>
    <div className={styles.position}>
      <h4 className={styles.title}>位置</h4>
      <div className={styles.content}>
        <div>
          <div>X</div>
          <Input value={objectState.left} onChange={changeHandler('left')} onKeyDown={submitHandler('left')} onBlur={submitHandler('left')} />
        </div>
        <div>
          <div>Y</div>
          <Input value={objectState.top} onChange={changeHandler('top')} onKeyDown={submitHandler('top')} onBlur={submitHandler('top')} />
        </div>
      </div>
    </div>
  </div>)
};
export default BasePosition;
