import React, { useCallback } from 'react';
import ToolSectionTitle from 'components/Tools/ToolSectionTitle';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import styles from './index.module.scss';

const fullWidthType = {
    fixed: "固定比例",
    stretch: "隨螢幕縮放",
}

const inputInfo = [
    { label: fullWidthType.fixed, value: false },
    { label: fullWidthType.stretch, value: true }
];

const FullWidth = () => {
    const [{ fullWidthInfo: { mode: fullWidthMode } }] = useStore(StoreTypes.reader);
    const selectHandler = useCallback((value) => {
        let mode = false;
        switch (value) {
            case fullWidthType.fixed:
                mode = false;
                break;
            case fullWidthType.stretch:
                mode = true;
                break;
            default:
                break;
        }
        EventBus.emit({ event: ReaderToolsEvent.ClickFullWidthEvent, payload: { fullWidthMode: mode } })
    }, [])

    return (
        <div className={styles.fullWidth}>
            <ToolSectionTitle label="顯示比例" />
            <div className={styles.labelGroup}>
                {
                    inputInfo.map(item =>
                        <label key={item.label}>
                            <input
                                type='radio'
                                name="fullwidth"
                                value={item.label}
                                checked={fullWidthMode === item.value}
                                onChange={(e) => selectHandler(e.target.value)}
                            />
                            <span >{item.label}</span>
                        </label>
                    )
                }
            </div>
        </div>
    )
};


export default FullWidth;
