/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import DialogModule from "components/Dialogs/";
import { LOGIN_POPUP, LOGIN_CONFIRM_POPUP ,LOGIN_BTN } from "constants/loginTypes";
import * as actionTypes from "constants/actionTypes";
import { ReaderToolsEvent } from "events/EventTypes";
import { EventBus } from "events/EventBus";
import { AnnotationType } from 'constants/annotationTypes';

import { logoutNaniOneClass} from 'components/Login/Popup/login.js';

const ConfirmPopup = () => {
	const [isDialog, setIsDialog] = useState(true);
    const [{ loginBtn ,confirmPopupState}, loginDispatch] = useStore(StoreTypes.login);
  	let [{ isLogin, name }, userDispatch] = useStore(StoreTypes.user);
    const [{ annotationId }, annotationDispatch] = useStore(StoreTypes.annotation);

	const TextContent = (
		<div className={styles.popUp}>
			<div className={styles.title}>登出</div>
			<div className={styles.content}>是否登出 OneBook？</div>
		</div>
	);

	const cancel = useCallback(() => {
		loginDispatch({ type: LOGIN_CONFIRM_POPUP, confirmPopupState: false });
	}, [loginDispatch]);

	const ok = useCallback(() => {
		annotationId&&annotationDispatch({
			type: actionTypes.UPDATE_ANNOTATION_INFO,
			annotationId,
			annotationType: AnnotationType.GUEST
		});
		userDispatch({
			type: actionTypes.UPDATE_USER_INFO,
			name:'',
			isLogin: false, 
			token: null, 
			userId:null,
			role: 'GUEST',
		});
		logoutNaniOneClass((result) => {
			
		});
		//userDispatch({ type: actionTypes.RESET_USER_INFO });

		loginDispatch({ type: LOGIN_BTN, loginBtn: false });
		loginDispatch({ type: LOGIN_CONFIRM_POPUP, confirmPopupState: false });

		EventBus.emit({
			event: ReaderToolsEvent.SetSyncCameraEvent,
			payload: { isOpen: false },
		});

		// EventBus.emit({ event: UserEvent.LoginEvent });
		window.electron&&window.electron.ipcRenderer.send("set-token", null);
	},[annotationDispatch, annotationId, loginDispatch, userDispatch]);

	const action = (
		<div className={styles.dialogBtn}>
			<div className={styles.cancel} onClick={() => cancel()}>
				取消
			</div>
			<div className={styles.ok} onClick={() => ok()}>
				確定
			</div>
		</div>
	);

	return (
		<DialogModule
			open={isDialog}
			setOpen={setIsDialog}
			content={TextContent}
			action={action}
		/>
	);
};

export default ConfirmPopup;
