import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';
import { debounce } from 'util/debounce';
import classnames from 'classnames';
import { ReaderToolsEvent, CanvasEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import * as types from 'constants/actionTypes';
import Button from 'components/Button/index';
import Icon from 'components/Icon';
import { useReadAnnotations } from 'customHooks/db';

//帶入參數範例
//https://localhost:3009/6373dc804106e3f2?&roomId=ec7311ad348f40f0bd754e7bf9b83109&userName=horrytutor&role=tutor&id=horrytutor&controller=true&remoteId=3e7a7880-80bd-11eb-b40f-f3571e9499fe

const SynchronousCamera = ({ isSynchronousCamera }) => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [{ annotationId }] = useStore(StoreTypes.annotation);
    const [{ courseId: roomId, timeSpanId, firestore, startAt, endAt, organizationId, rewardInfo, finishedBefore }, courseDispatch] = useStore(StoreTypes.course);
    const [{ role, token, userId, name }, userDispatch] = useStore(StoreTypes.user);
    const [{ remoteId, onConnected }] = useStore(StoreTypes.course);
    const iframeRef = useRef();
    const closeBtnRef = useRef();
    const [iframeUrl, setIframeUrl] = useState(null)
    const { readAnnotationById } = useReadAnnotations();

    useEffect(() => {
        if (!annotationId) return;
        setIframeUrl(`${process.env.REACT_APP_ONECLICK_URL}/qr?bookId=${bookId}&roomId=${annotationId}&userName=${name}_remote&role=${role}_REMOTE&id=${userId}_remote&site=onebook`)
    }, [bookId, annotationId, name, userId, role])

    useEffect(() => {
        if (!remoteId || !userId) return
        setIframeUrl(`${process.env.REACT_APP_ONECLICK_URL}/Sender?remoteId=${remoteId}`)
    }, [remoteId, userId])

    useEffect(() => {
        if (!iframeUrl) return
        var iframe = document.getElementById('chimeiframe').contentWindow;
        if (isSynchronousCamera) {
            iframe.postMessage(remoteId, "*");
        }
    }, [iframeUrl, isSynchronousCamera, remoteId])

    const onebookCallback = useCallback(async (payload) => {
        if (remoteId || !roomId) return
        // payload
        // 隱藏視窗:{msg:"HideWindow"}
        // 關閉同步:{msg:"Disconnect"}
        const { msg } = payload.data;

        switch (msg) {
            case "HideWindow":
                EventBus.emit({
                    event: ReaderToolsEvent.SetSyncCameraEvent,
                    payload: { isOpen: false },
                });
                break;
            case "Disconnect":
                EventBus.emit({
                    event: ReaderToolsEvent.SetSyncOnConnected,
                    payload: { onConnected: false },
                });
                break;
            case "OnConnection":
                EventBus.emit({
                    event: ReaderToolsEvent.SetSyncCameraEvent,
                    payload: { isOpen: true },
                });
                EventBus.emit({
                    event: ReaderToolsEvent.SetSyncOnConnected,
                    payload: { onConnected: true },
                });
                const result = await readAnnotationById({ id: roomId });

                EventBus.emit({ event: CanvasEvent.CanvasSavedEvent, payload: { annotations: result.annotations } });
                break;
            default:
                break;
        }

    }, [remoteId, roomId, readAnnotationById])

    const iframeOnLoad = useCallback((e) => {
        window.addEventListener("message", onebookCallback, false);
        return () => {
            window.removeEventListener("message", onebookCallback)
        }
    }, [onebookCallback])

    const onCloseHandler = useCallback((e) => {
        var iframe = document.getElementById('chimeiframe').contentWindow;
        if (!iframe) return
        //iframe.postMessage('sendMessage', "*");
        EventBus.emit({
            event: ReaderToolsEvent.SetSyncCameraEvent,
            payload: { isOpen: false },
        });
    }, [])

    return (
        <div id="synchronousCamera" className={classnames(styles.syncContent, { [styles.show]: isSynchronousCamera })}>
            <div className={styles.syncBG}></div>
            <div className={styles.closeBtn} >
                <div >
                    <Button title="關閉" onClick={onCloseHandler} ref={closeBtnRef}>
                        <Icon name="Close" />
                    </Button>
                </div>
            </div>
            <div className={styles.iframeContent}>
                <div className={styles.iframeArea}>
                    {
                        iframeUrl && <iframe allow="camera;microphone;display-capture" className={styles.iframe} id="chimeiframe" ref={iframeRef} title={"即時影像"} src={iframeUrl} onLoad={() => iframeOnLoad()} width="100%" height="100%" ></iframe>
                    }
                </div>
            </div>
        </div>
    )
}

export default SynchronousCamera;
