export const storeUndoAnnotation = (newAnnotation, undoAnnotations) => {
    let shouldStore;
    //比較新舊步驟是否相同
    const pages = Object.keys(newAnnotation);
    const length = undoAnnotations.length;
    if (pages.length === 1) {
        shouldStore = newAnnotation[pages[0]] !== undoAnnotations[pages[0]][length - 1];
    } else {
        const prev = pages[0];
        const next = pages[1];
        const prevLength = undoAnnotations[prev].length;
        const nextLength = undoAnnotations[next].length;
        shouldStore = newAnnotation[prev] !== undoAnnotations[prev][prevLength - 1] || newAnnotation[next] !== undoAnnotations[next][nextLength - 1];
    }
    return shouldStore
}

// export const escapeEmptyItext = (newAnnotation) => {
//     console.log('escapeEmptyItext', newAnnotation)
//     let modifiedAnnotation = {...newAnnotation};
//     const pages = Object.keys(newAnnotation);
//     pages.forEach(page => {
//         console.log(JSON.parse(newAnnotation[page]).object)
//         // const hasIText = newAnnotation[page].object.some(fabricObject => fabricObject.type === 'i-text');
//         // if (hasIText) {
            
//         // }
//     })
//     return modifiedAnnotation
// }