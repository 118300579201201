import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { Roles } from 'constants/role';
import { AnnotationType } from 'constants/annotationTypes';
import { useActiveTeacherStrategy } from './ActiveTeacherStrategy';
import { useInactiveTeacherStrategy } from './InactiveTeacherStrategy';
import { useActiveStudentStrategy } from './ActiveStudentStrategy';
import { useInactiveStudentStrategy } from './InactiveStudentStrategy';
import { usePrepareClassStrategy } from './PrepareClassStrategy';
import { useParentStrategy } from './ParentStrategy';
import { useStudentStrategy } from './StudentStratyge';
import { useEditInteractiveObjectStrategy } from './EditInteractiveObjectStrategy';
import { useGuestStrategy } from './GuestStrategy';
import { useOneClassTeacherStrategy } from './OneClassTeacherStrategy';
import { useOneClassStudentStrategy } from './OneClassStudentStrategy';
import { useOneTutorTeacherStrategy } from './OneTutorTeacherStrategy';
import { useOneTutorStudentStrategy } from './OneTutorStudentStrategy';
import { useOneTutorSpectatorsStrategy } from './OneTutorSpectatorsStrategy';

export const useReaderStrategyDecider = () => {
    const [{ role }] = useStore(StoreTypes.user);
    const [{ annotationType, activityInfo: { activityTarget, attendeeNumber } }] = useStore(StoreTypes.annotation);
    const activeTeacherStrategy = useActiveTeacherStrategy();
    const inactiveTeacherStrategy = useInactiveTeacherStrategy();
    const activeStudentStrategy = useActiveStudentStrategy();
    const inactiveStudentStrategy = useInactiveStudentStrategy();
    const prepareClassStrategy = usePrepareClassStrategy();
    const parentStrategy = useParentStrategy();
    const studentStrategy = useStudentStrategy();
    const editInteractiveObjectStrategy = useEditInteractiveObjectStrategy();
    const guestStrategy = useGuestStrategy();
    const oneTutorTeacherStrategy = useOneTutorTeacherStrategy();
    const oneTutorStudentStrategy = useOneTutorStudentStrategy();
    const oneTutorSpectatorsStrategy = useOneTutorSpectatorsStrategy();
    const oneClassTeacherStrategy = useOneClassTeacherStrategy();
    const oneClassStudentStrategy = useOneClassStudentStrategy();

    const getReaderStrategy = useCallback(() => {
        if (annotationType === AnnotationType.ACTIVITY && activityTarget) {
            if (role === Roles.TEACHER) {
                if (activityTarget === attendeeNumber) {
                    return activeTeacherStrategy;
                } else {
                    return inactiveTeacherStrategy;
                }
            } else if (role === Roles.STUDENT) {
                if (activityTarget === attendeeNumber) {
                    return activeStudentStrategy;
                } else {
                    return inactiveStudentStrategy;
                }
            }
        } else if (annotationType === AnnotationType.CLASS_PREPARATION) {
            if (role === Roles.TEACHER||role === Roles.TEACHER_REMOTE) {
                return prepareClassStrategy;
            } else if (role === Roles.PARENT) {
                return parentStrategy;
            } else if (role === Roles.STUDENT) {
                return studentStrategy;
            }
        } else if (annotationType === AnnotationType.INTERACTIVE_OBJECT) {
            return editInteractiveObjectStrategy;
        } else if (annotationType === AnnotationType.GUEST) {
            return guestStrategy;
        } else if (annotationType === AnnotationType.ONE_TUTOR) {
            if (role === Roles.TUTOR) {
                return oneTutorTeacherStrategy;
            } else if (role === Roles.TUTOR_USER) {
                return oneTutorStudentStrategy;
            } else if (role === Roles.TUTOR_SPECTATORSUSER) {
                return oneTutorSpectatorsStrategy;
            }
        } else if (annotationType === AnnotationType.ONE_CLASS) {
            if (role === Roles.ONECLASS_TEACHER) {
                return oneClassTeacherStrategy;
            } else if (role === Roles.ONECLASS_STUDENT) {
                return oneClassStudentStrategy;
            }
        }
    }, [
        activeStudentStrategy,
        activeTeacherStrategy,
        activityTarget,
        annotationType,
        attendeeNumber,
        editInteractiveObjectStrategy,
        inactiveStudentStrategy,
        inactiveTeacherStrategy,
        guestStrategy,
        prepareClassStrategy,
        role
    ]
    );

    return { getReaderStrategy };
};
