import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const useInternalHtmlWindowCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ json: { internalHtmlInfo } }) => {
        try {
            const { src, toolbarOpen } = JSON.parse(internalHtmlInfo);
            const component = ModalComponentGenerator.generate(ModalComponentType.IFrame, { internalHtmlInfo: { src: ExtendedResourceRepository.getInternalHtmlSrc({ bookId, filename: src }), toolbarOpen } });
            setModalComponents(component);
            openModal();
        } catch (e) {
            console.error('InternalHtmlWindowCommand execute error', e);
        }
    }, [bookId, openModal, setModalComponents]);

    return { execute };
};
