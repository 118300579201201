import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { CanvasLevelType } from 'constants/levelTypes';

const LevelTools = ({target:object}) =>{
    const setLevelHandler = useCallback(levelType => e => {
        EventBus.emit({ event: CanvasEvent.SetCanvasLevelEvent, payload: { object, levelType } });
    },[object])

    return (
        <div className={styles.levelTools}>
            <h4>圖層</h4>
            <button onClick={setLevelHandler(CanvasLevelType.FRONT)}>移至最前</button>
            <button onClick={setLevelHandler(CanvasLevelType.BACK)}>移至最後</button>
        </div>
    )
};


export default LevelTools;