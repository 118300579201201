export const Roles = {
    TEACHER: 'TEACHER',
    TEACHER_REMOTE: 'TEACHER_REMOTE',
    PARENT: 'PARENT',
    STUDENT: 'STUDENT',
    EDITOR: 'EDITOR',
    GUEST: 'GUEST',
    TUTOR: 'TUTOR',
    TUTOR_USER: 'TUTOR_USER',
    ONECLASS_TEACHER: 'ONECLASS_TEACHER',
    ONECLASS_STUDENT: 'ONECLASS_STUDENT',
};
