import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import useSetState from 'customHooks/setState';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { InteractiveObjectEvent } from 'events/EventTypes';
import { preserveSVGAspectRatio } from 'util/book';
import { flat } from 'util/array';
import { LargerClickAreaStamps } from 'constants/stampTypes';
import Repository from 'repositories/Repository';


const { BookContentRepository } = Repository;

export const InteractiveObjectView = ({
  svgString,
  pageIndex,
  bookInfo,
  interactiveObjectState,
  setInteractiveObjectState,
  interactiveObjectJSON
}) => { 
  const [{ isDoublePageMode, isMarkModeShow, fullWidthInfo }] = useStore(
    StoreTypes.reader
  );
  const [{ role }] = useStore(StoreTypes.user);
  const [{ stampControls }] = useStore(StoreTypes.settings);
  const stampGroupsDisabledIds = useMemo(() => stampControls[bookInfo.bookId] || [], [bookInfo.bookId, stampControls])

  const [{ stampGroups }] = useStore(StoreTypes.stamp);

  const [{ musicModal: { activeObjectId, isShowing, vtts = [], isFollowReadMode, currentTime } }] = useStore(StoreTypes.global);
  const [{ isActive }] = useStore(StoreTypes.canvas);
  const [{
    isAlone,
  }, setState] = useSetState({
    isAlone: false,
  });


  const ref = useRef(null);
  const downRef = useRef(null);
  const { width, height, LRFlip } = bookInfo;
  const { userAgent } = navigator;

  useEffect(() => {
    const node = ReactDOM.findDOMNode(ref.current);
    let lastActiveObjectId = '';
    if (isShowing) {
      setState({ isAlone: false })
    }
    vtts.forEach((vtt, index, array) => {
      if (index === 0 && currentTime <= array[index + 1].startTime) {
        lastActiveObjectId = vtt.text
      }
      if (index !== 0 && index < array.length - 1) {
        if (vtt.endTime <= currentTime && currentTime <= array[index + 1].startTime) {
          lastActiveObjectId = vtt.text
        }
        if (vtt.endTime >= currentTime && currentTime >= vtt.startTime) {
          lastActiveObjectId = vtt.text
        }
      }
      if (index === array.length - 1 && currentTime >= vtt.startTime) {
        lastActiveObjectId = vtt.text
      }
    })

    if (!node || (isAlone && !lastActiveObjectId)) return;
    const clonedNode = node.cloneNode(true);
    const clonedSvgNode = clonedNode.querySelector('svg');
    const downNode = ReactDOM.findDOMNode(downRef.current);
    if (!downNode.querySelector('svg')) {
      downNode.appendChild(clonedSvgNode);
    } else {
      downNode.replaceChild(clonedSvgNode, downNode.querySelector('svg'));
    }

    const groups = node.querySelectorAll('g');

    let pageIndexInfo = [pageIndex];
    let interactiveObjects;
    let interactiveObjectsHidden = [];
    let interactiveObjectClickable = [];
    let interactiveObjectControlObject = [];
    let interactiveObjectCankeepHighLight = [];
    let interactiveObjectEmptyContentType = [];
    let interactiveObjectStampsHidden = [];

    for (let obj in interactiveObjectJSON) {
      if (~pageIndexInfo.indexOf(parseInt(obj))) {
        const interactiveObjectArr = Object.values(interactiveObjectJSON[obj]);
        
        interactiveObjectArr.forEach(item => {
          if (!BookContentRepository.getIsShowInteractiveObject({ role, interactiveObject: item })) {
            interactiveObjectsHidden.push(item.id)
          }
          if (item.isClickable === true) {
            interactiveObjectClickable.push(item.id)
          }
          if (item.controlObject && item.contentType === 'ControlStage') {//把type為ControlStage的controlObject存起來
            interactiveObjectControlObject.push(item.controlObject)
          }
          if (item.mouseOver && item.contentType === 'Audio') {
            interactiveObjectCankeepHighLight.push(item.id);
          }
          if (!item.contentType && !item.style) {
            interactiveObjectEmptyContentType.push(item.id)
          }

          const stampGroupsDisabledNames = flat(stampGroupsDisabledIds
          .map(id => stampGroups[id] ? stampGroups[id].stamps : null).filter(id => id));

          if(stampGroupsDisabledNames.includes(item.stampName)){
            interactiveObjectStampsHidden.push(item.id);
          }

        })
        interactiveObjects = interactiveObjectJSON[obj]
        break;
      }
    }

    const checkMouseOver = (function (group) {
      return this.interactiveObjects.some(data => data.id === group.id && data.mouseOver);
    }).bind({ interactiveObjects: Object.values(interactiveObjects) });

    let targetObjectId = '';
    const cloneGroups = clonedSvgNode.querySelectorAll('g');

    const nextInteractiveObjectControlObject = [...new Set(interactiveObjectControlObject)]
    groups.forEach((group, index) => {
      if (LargerClickAreaStamps.includes(group.id)) { // 特定按鈕要加大選取範圍
        group.parentElement.parentElement.classList.add(styles.largerClickArea);
      }
      if (group.id && group.id.match(/^[\d|a-zA-Z]+$/)) {

        const element = cloneGroups[index];

        if(interactiveObjectStampsHidden.includes(group.id)){
          group.style.display = 'none';
          element.style.display = 'none';
          return;
        } else {
          group.style.display = 'block';
          element.style.display = 'block';
        }

        if (interactiveObjectsHidden.includes(group.id)) {
          group.style.display = 'none';
          element.style.display = 'none';
        }

        const addClickable = () => {
          group.classList.add(styles.clickable);
          element.classList.add(styles.clickable);
        }

        const removeClickable = () => {
          group.classList.remove(styles.clickable);
          element.classList.remove(styles.clickable);
        }

        const isPainterClickable = interactiveObjectClickable.includes(group.id);
        const isContentTypeAndStyleEmpty = interactiveObjectEmptyContentType.includes(group.id);

        //目前要求的書本2960c53f8529edf1 有上下一步的解答 空白處不能出現手指
        if (nextInteractiveObjectControlObject.length > 0) {
          if (isPainterClickable) {
            addClickable();
          } else {
            if (!isActive) {
              addClickable();
            } else {
              removeClickable();
            }
          }
        } else {
          if (!isActive) {
            //互動物件的Style和ContentType 未選擇的時候，也不要出現手指頭
            if (isContentTypeAndStyleEmpty) {
              removeClickable();
            } else {
              addClickable();
            }

          } else {
            if (isPainterClickable && !isContentTypeAndStyleEmpty) {
              addClickable();
            } else {
              removeClickable();
            }
          }
        }

        if (checkMouseOver(group)) {
          const subGroup = group.querySelector('g');
          if (subGroup) {
            element.querySelector('g').style.opacity = '0';
          }
          element.querySelectorAll('rect').forEach(target => {
            target.style["mix-blend-mode"] = 'normal';
            target.style["opacity"] = '0';
          })
        }

        const handler = event => {
          if (targetObjectId !== group.id) return;
          if (!interactiveObjectClickable.includes(group.id) && isActive) return;
          event.preventDefault();
          if (interactiveObjectCankeepHighLight.includes(group.id)) {
            const subGroup = group.querySelector('g');
            setState({
              isAlone: true,
            })
            cloneGroups.forEach(cloneEl => {
              cloneEl.querySelectorAll('rect').forEach(target => {
                target.classList.remove(styles.objectHover);
              })
            })
            element.querySelectorAll('rect').forEach(target => {
              target.classList.remove(styles.objectHover)
            })

            if (subGroup) {
              element.querySelector('g').classList.add(styles.gOpacity);
            }
            element.querySelectorAll('rect').forEach(target => {
              target.classList.add(styles.objectHover);
            })
          }
          EventBus.emit({
            event: InteractiveObjectEvent.ClickInteractiveObjectEvent,
            payload: {
              id: group.id,
              pageIndex,
              isDoublePageMode,
              interactiveObjectState,
              setInteractiveObjectState,
              target: event.target
            }
          });
        };

        const mouseOverHandler = (event, isImportant) => {
          const subGroup = group.querySelector('g');
          if (subGroup) {
            element.querySelector('g').style.opacity = '1';
          }
          if (isImportant) {
            const vvt = vtts.filter(item => item.text === (lastActiveObjectId || activeObjectId))[0];
            const endTime = vvt.endTime;

            element.querySelectorAll('rect').forEach(target => {
              target.classList.add(styles.objectHover);
              if (isFollowReadMode) {
                if (currentTime >= endTime) {
                  target.classList.remove(styles.objectHover);
                  target.classList.add(styles.studentRead);
                } else {
                  target.classList.remove(styles.studentRead);
                  target.classList.add(styles.objectHover);
                }
              }
            })
          } else {
            element.querySelectorAll('rect').forEach(target => {
              target.style["mix-blend-mode"] = 'multiply';
              target.style["opacity"] = 0.3;
            })
          }
        };
        const mouseOutHandler = event => {
          const subGroup = group.querySelector('g');
          if (subGroup) {
            element.querySelector('g').style.opacity = '0';
          }
          element.querySelectorAll('rect').forEach(target => {
            target.style["mix-blend-mode"] = 'normal';
            target.style["opacity"] = '0';
          })
        };


        const startHandler = () => {
          targetObjectId = group.id
        }

        // if (/Android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
        //   group.ontouchstart = startHandler;
        //   group.ontouchend = handler;
        // } else {
        group.onpointerdown = startHandler;
        group.onpointerup = handler;
        if (checkMouseOver(group)) {
          group.onmouseover = mouseOverHandler;
          group.onmouseout = mouseOutHandler;
        }
        // }
        if (lastActiveObjectId.indexOf(group.id) > -1) {
          setState({ isAlone: false });
          mouseOverHandler(null, true);
        }
        if (!isShowing) {
          lastActiveObjectId = '';
          element.querySelectorAll('rect').forEach(target => {
            if (isFollowReadMode) {
              target.classList.remove(styles.objectHover);
              target.classList.remove(styles.studentRead);
            }
          });
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stampGroupsDisabledIds, activeObjectId, isDoublePageMode, interactiveObjectState, pageIndex, setInteractiveObjectState, userAgent, fullWidthInfo.mode, interactiveObjectJSON, role, isActive, isShowing, currentTime]);

  const toggleInteractive = useCallback(node => {
    if (!node) return;
    for (let item of interactiveObjectState.state) {
      item.pageIndex.forEach(pageIndex => {
        const groups = node.querySelectorAll('g');
        if (groups) {
          for (let displayIndex in item.display) {
            const display = item.display[displayIndex];
            for (let group of groups) {
              if (group.id === display.id) {
                for (let child of group.children) {
                  if (child.style) {
                    child.style.opacity = display.opacity;
                  }
                }
                break;
              }
            }
          }
          for (let stageIndex in item.stage) {
            const stage = item.stage[stageIndex];
            let phases = [];
            for (let group of groups) {
              if (stage.phase.includes(group.id)) {
                phases.push(group);
              }
            }
            phases.forEach((phase, i) => {
              const phaseIndex = stage.phase.indexOf(phase.id);
              if (~phaseIndex) {
                const element = phase.children[0];
                element.style.opacity =
                  stage.revealStage ^ (phaseIndex >= stage.current);
              }
            });
          }
          for (let imageIndex in item.image) {
            const image = item.image[imageIndex];
            for (let group of groups) {
              if (group.id === image.id) {
                const element = group.children[0];
                element.setAttributeNS(
                  'http://www.w3.org/1999/xlink',
                  'href',
                  image.src
                );
                // replace image from item.src
                break;
              }
            }
          }
        }
      });
    }
  }, [interactiveObjectState.state])

  useEffect(() => {
    const refNode = ReactDOM.findDOMNode(ref.current);
    const downRefNode = ReactDOM.findDOMNode(downRef.current);
    toggleInteractive(refNode);
    toggleInteractive(downRefNode)

  }, [interactiveObjectState, fullWidthInfo.mode, toggleInteractive]);

  return (
    <>
      <div
        className={classnames(
          styles.svgContent,
          styles.hide,
          styles[
          isMarkModeShow
            ? 'canvasObjectSavedSvgContentDisable'
            : 'canvasObjectSavedSvgContentEnable'
          ]
        )}
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: preserveSVGAspectRatio({
            svg: svgString,
            width,
            height,
            LRFlip,
            pageIndex,
            isDoublePageMode
          })
        }}
      />
      <div className={classnames(
        styles.svgContent,
        styles[
        isMarkModeShow
          ? 'canvasObjectSavedSvgContentDisable'
          : 'canvasObjectSavedSvgContentEnable'
        ],
        styles.down
      )} ref={downRef} />
    </>
  );
};
