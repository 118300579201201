import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import paiQuizLoading from './paiQuizLoading.json';

export const LottiePlayer = ({ width='70%' }) => {
    return (
        <Player
            loop
            autoplay
            src={paiQuizLoading}
            style={{ width }}
        >
        </Player>
    )
}