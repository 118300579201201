import React from 'react';
import VideoPlayer from 'components/common/VideoPlayer';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const VideoModalComponent = ({ bookId, videoInfo }) => {
  console.log("videoInfo",videoInfo);
  // const error=ExtendedResourceRepository.openVideoBlank({ 
  //   bookId,
  //   pathName: videoInfo.src})

  const url=videoInfo.src.includes("https")?videoInfo.src:ExtendedResourceRepository.getVideoSrc({
    bookId,
    filename: videoInfo.src
  })
  console.log("url",url);
  return (
    <div className={styles.videoModalComponent} key={bookId}>
      <VideoPlayer
        key="VideoPlayer"
        //onError={() => error}
        src={url}
        seekList={videoInfo.seekList || []}
        subtitles={videoInfo.subtitles
          .filter(subtitle => subtitle.src)
          .sort(() => -1)
          .map(subtitle => ({
            ...subtitle,
            src: ExtendedResourceRepository.getSubtitleSrc({
              bookId,
              filename: subtitle.src
            })
          })) || []}
        questionList={videoInfo.questionList || []}
        control
      />
    </div>
  );
};
