import { useCallback, useRef } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { ReaderToolsEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';

const useModal = () => {
  const [{ modal }, globalDispatch] = useStore(StoreTypes.global);
  const onCloseRef = useRef(null);

  const openModal = useCallback((onClose) => {
    if (onCloseRef.current) {
      onCloseRef.current();
    }
    onCloseRef.current = onClose;
    globalDispatch({
      type:types.OPEN_MODAL
    })
  },[globalDispatch]);

  const closeModal = useCallback(() => {
    if (onCloseRef.current) {
      onCloseRef.current();
      onCloseRef.current = null;
    }
    globalDispatch({
      type:types.CLOSE_MODAL
    })
    EventBus.emit({ event: ReaderToolsEvent.ClosePannelEvent, payload: { pannlType: "" } });
  },[globalDispatch]);

  const setModalComponents = useCallback((components) => {
    globalDispatch({
      type:types.SET_MODAL_COMPONENTS,
      payload:{
        components
      }
    })
  },[globalDispatch])

  return [
    {
      isShowing: modal.isShowing,
      components: modal.components
    },
    {
      openModal,
      closeModal,
      setModalComponents
    }
  ];
};

export default useModal;
