import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from "context";
import CircularProgress from '@material-ui/core/CircularProgress'; 

const UploadFileDrapDown = ({ layout, src, inputTextHandler }) => {
  const [{ token }] = useStore(
    StoreTypes.user
  );
  const [isDragActive, setIsDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('0');
  const [url, setUrl] = useState(src);
  const [loading,setLoagin]=useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    setLoagin(true)
    const url = 'https://asia-east1-bookstore-demo-f920e.cloudfunctions.net/files/upload'; // 你的上傳URL
    const formData = new FormData();

    let fileType
    let fileName
    let fileObject;
    acceptedFiles.forEach(file => {
      fileType = file.type;
      fileName = file.name
      fileObject = file
      formData.append('files', file); // 使用FormData附加文件
    });

    // fetch(url, {
    //   method: 'POST',
    //   body: formData,
    //   headers: { Authorization: token }
    // }).then((res) => res.json())
    //   .then(response => {
    //     console.log(response);
    //     if (response.status === "success") {
    //       setUrl(response.data.publicUrl)
    //       inputTextHandler(response.data.publicUrl)
    //       setUploadProgress('文件上傳成功！');
    //     } else {
    //       setUploadProgress('文件上傳失敗。');
    //     }
    //   })
    //   .catch(error => {
    //     //setUploadProgress('上傳過程中出現錯誤：', error);
    //   });

    console.log("fileType", fileType);
    console.log("fileName", fileName);
    let signedUrl = "https://storage.googleapis.com/bookstore-dev-a9958.appspot.com/public/test5?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=firebase-adminsdk-2rlrk%40bookstore-dev-a9958.iam.gserviceaccount.com%2F20230913%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20230913T060056Z&X-Goog-Expires=900&X-Goog-SignedHeaders=host&X-Goog-Signature=73917c7b86dabd303946a5644f0d37c7f786cc67a01ed7149e09aaf5a5c2f7f25a01e30cffec741d1b8be7a0bf3e674782141ac2fa0882bb52b0150773c562ced0c4c8abe27115ec2be933f4c5d68d4c5b9023dfdf413b1fcd15bc989de01695924d93ae64a5d062775a3724e38049b397faf2525ed680908295296dd9aa93dc0fb145a0b5955a2214fd73f199e68a3c60794456dea5b7180edd0343d871db683783a5f4fb5838234a798ec0a631df079bb361ab749d41c85014c003c1444c6d823b6b97755af954acfd2b5d6649ad2ec783582169283712e28b9786685fbdac6a7feaf6e89181d1ccf31ca36c3499ac0f5b9cc050c3c4306e5b4b84b2b81096";

    fetch(`https://asia-east1-bookstore-demo-f920e.cloudfunctions.net/files/GCPSignedUrl?filename=${fileName}`, {
      method: 'GET',
      headers: { Authorization: token }
    }).then((res) => res.json())
      .then(response => {
        console.log(response);
        if (response.status === "success") {
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", response.data.signedUrl[0], true);

          xhr.onprogress = (event) => {
            const loaded = event.loaded;
            console.log('progress')
            console.log("loaded", loaded);
          }
          xhr.onload = () => {
            console.log(xhr)
            const status = xhr.status;
            console.log("xhr", xhr.responseText);
            if (status === 200) {

              console.log("fileName", fileName);
              
              fetch(`https://asia-east1-bookstore-demo-f920e.cloudfunctions.net/files/makePublic`, {
                method: 'POST',
                body: JSON.stringify({ filename: fileName }),
                headers: { Authorization: token ,"Content-Type": "application/json"}
              }).then((res) => res.json())
                .then(response => {
                  setLoagin(false)
                  if (response.status === "success") {
                    setUrl(response.data)
                    inputTextHandler(response.data)
                    console.log(">>>>>>",response);
                  }
                })
            } else {
              alert("Something went wrong!");
            }
          };

          xhr.onerror = (err) => {
            console.log(err)
            alert("Something went wrong");
          };
          xhr.setRequestHeader("Content-Type", fileType);
          xhr.send(fileObject);
          setUploadProgress('文件上傳成功！');
        } else {
          setUploadProgress('文件上傳失敗。');
        }
      })
      .catch(error => {
        //setUploadProgress('上傳過程中出現錯誤：', error);
      });



    // const totalSize = formData.getAll('files').reduce((acc, file) => acc + file.size, 0);
    // const xhr = new XMLHttpRequest();

    // xhr.upload.addEventListener('progress', event => {
    //   const loaded = event.loaded;
    //   console.log("loaded", loaded);
    //   setUploadProgress(`進度：${parseInt((loaded / totalSize) * 100)}%`);
    // });

    // xhr.addEventListener('load', function () {

    //   if (xhr.status === 200) {
    //     console.log("xhr", xhr.responseText);
    //     // 上傳成功，可以在這裡處理伺服器的回應
    //     console.log('上傳成功');
    //   } else {
    //     // 上傳失敗，可以在這裡處理錯誤
    //     console.error('上傳失敗');
    //   }
    // });

    // xhr.open('PUT', signedUrl, true);
    // //xhr.setRequestHeader('Authorization', token);
    // xhr.send(formData);



    // 模擬上傳進度
    // const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);
    // let uploaded = 0;

    // const simulateUpload = () => {
    //   if (uploaded < totalSize) {
    //     const chunkSize = Math.min(10000, totalSize - uploaded);
    //     uploaded += chunkSize;
    //     const progress = (uploaded / totalSize) * 100;
    //     setUploadProgress(progress);

    //     // 模擬每隔一段時間更新進度
    //     setTimeout(simulateUpload, 500);
    //   }
    // };

    // // 開始模擬上傳
    // simulateUpload();
  }, [token]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDropAccepted: () => setIsDragActive(false),
  });

  return (
    <div
      className={classnames(styles.customDropzone)}
      {...getRootProps()}
    >
      {layout}
      <input {...getInputProps()} />
      {
        console.log("uploadProgress", uploadProgress)
      }
      <div className={classnames(styles.progressBar, styles[isDragActive && 'show'])}>
        {
          loading?<CircularProgress/>:uploadProgress === "0" ?
            "請拖曳檔案到此位置" :
            uploadProgress
        }
      </div>
      <div className={styles.filePath}>
        <div>
          {url ? url : '檔案路徑'}
        </div>
      </div>
    </div>
  );
};

export default UploadFileDrapDown;
