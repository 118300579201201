import { IReaderToolBarRepository } from './IReaderToolBarRepository';
import { DefaultLeftReaderTools, DefaultMiddleReaderTools, DefaultRightReaderTools } from 'constants/ReaderTools';
import { ReaderToolType, DefaultToolboxCategory, DefaultReaderTools } from 'constants/ReaderTools';

function CloudReaderToolBarRepository() { };
CloudReaderToolBarRepository.prototype = Object.create(IReaderToolBarRepository);

CloudReaderToolBarRepository.prototype.getDefaultReaderTools = () => {
    return DefaultReaderTools;
};

CloudReaderToolBarRepository.prototype.getLeftReaderTools = () => {
    return DefaultLeftReaderTools;
};

CloudReaderToolBarRepository.prototype.getMiddleReaderTools = () => {
    return DefaultMiddleReaderTools;
};

CloudReaderToolBarRepository.prototype.getRightReaderTools = async () => {
    const results = [...DefaultRightReaderTools];
    if (results.indexOf(ReaderToolType.OfflineReaderToolBox) >= 0) {
        results.splice(results.indexOf(ReaderToolType.OfflineReaderToolBox), 1)
    }
    return results;
};

CloudReaderToolBarRepository.prototype.getGuestReaderToolBarWhitelist = () => {
    return [
        ReaderToolType.Menu,
        // ReaderToolType.Bookshelf,
        ReaderToolType.PageMode,
        ReaderToolType.Mark,
        ReaderToolType.Drag,
        ReaderToolType.Select,
        ReaderToolType.Painter,
        ReaderToolType.Painting,
        ReaderToolType.Highlighter,
        ReaderToolType.Shape,
        ReaderToolType.Line,
        ReaderToolType.DeleteAll,
        ReaderToolType.Delete,
        ReaderToolType.ArrowLeft,
        ReaderToolType.ArrowRight,
        ReaderToolType.PageSearcher,
        ReaderToolType.FourZoomIn,
        ReaderToolType.AllScreen,
        ReaderToolType.IntervalZoomIn,
        ReaderToolType.FullScreen,
        ReaderToolType.Whiteboard,
        ReaderToolType.NumberPicker,
        ReaderToolType.Img,
        ReaderToolType.Text,
        //ReaderToolType.SVG,
        ReaderToolType.Bookmark,
        ReaderToolType.ReaderOption,
        ReaderToolType.RightSideBar,
        ReaderToolType.FullWidth,
        ReaderToolType.OfflineReaderToolBox,
        ReaderToolType.StickyNote,
        ReaderToolType.Link,
        ReaderToolType.MathTools,
        ReaderToolType.Undo,
        ReaderToolType.Redo,
    ];
};

CloudReaderToolBarRepository.prototype.getReaderToolboxCategory = () => {
    return [...DefaultToolboxCategory]
};

export default new CloudReaderToolBarRepository();
