import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import Input from 'components/common/Input';
import useSetState from 'customHooks/setState';

const BaseSize = ({ target: object }) => {

  const [objectState, setObject] = useSetState({ ...object });

  const valueCalc = useCallback((property, value) => {
    value = (value && parseFloat(value)) || 1;
    value = property === 'scaleY' ? value / object.height : value / object.width;
    return value;
  }, [object.height, object.width])

  const submitHandler = useCallback(property => e => {
    let { value } = e.target;
    let key = e.which || e.keyCode;
    if (key && key !== 13) return;
    value = valueCalc(property, value);
    EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property, value } })
  }, [object, valueCalc]);

  const changeHandler = useCallback(property => e => {
    let { value } = e.target;
    value = valueCalc(property, value);
    setObject({ [property]: value });
  }, [setObject, valueCalc]);

  return object && (<div className={styles.baseSize}>
    <div className={styles.size}>
      <h4 className={styles.title}>大小</h4>
      <div className={styles.content}>
        <div>
          <div>長</div>
          <Input
            type="number"
            className={styles.border}
            value={Math.round(objectState.scaleY * objectState.height)}
            onChange={changeHandler('scaleY')}
            onKeyDown={submitHandler('scaleY')}
            onBlur={submitHandler('scaleY')}
          />
        </div>
        <div>
          <div>寬</div>
          <Input
            type="number"
            className={styles.border}
            value={Math.round(objectState.scaleX * objectState.width)}
            onChange={changeHandler('scaleX')}
            onKeyDown={submitHandler('scaleX')}
            onBlur={submitHandler('scaleX')}
          />
        </div>
      </div>

    </div>
  </div>)
};
export default BaseSize;
