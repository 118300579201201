import React from 'react';
import styles from './index.module.scss';
import ToolsPosition from 'components/Tools/ToolsPosition';
import FullWidth from 'components/Tools/FullWidth';
import ObjectPointerEventTools from 'components/Tools/ObjectPointerEventTools';
import PageTypeSwitch from 'components/Tools/PageTypeSwitch';

export const ReaderOptionPanel = () => {
    return (
        <div>
            <ToolsPosition />
            <div className={styles.division_line} />
            <FullWidth />
            <div className={styles.division_line} />
            <ObjectPointerEventTools />
            <div className={styles.division_line} />
            <PageTypeSwitch />
        </div>
    );
};
