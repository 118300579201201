import React from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
const Modal = ({ isShowing, closeModal, children }) => {
    return isShowing ? ReactDOM.createPortal(
        <div className={styles.modalWrapper}>
          <div className={styles.modalOverlay}  onClick={closeModal}/>
          <div className={styles.modalContent}>
              {children}
          </div>
        </div>, document.body
      ) : null;
}

export default Modal;