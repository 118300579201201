import React from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import Draggable from 'react-draggable';
import oneLinkSuccess from 'components/PaiQuiz/oneLinkSuccess.png';

const BoxModal = ({ title, content, bottom, onCloseButtonClick, goBack, needConfirmClose, goToOneLink, goToOneLinkLink }) => {
  const paiQuizContent = () => {
    if (needConfirmClose) {
      return (<div className={styles.boxModal}>
        <div className={styles.controlHead}>
          <h1>確定要關閉嗎？</h1>
        </div>
        <p className={styles.confirmDialog}>建議預先查看<span>「作答紀錄」</span>並儲存，<br />
          若關閉本視窗，會造成紀錄遺失並無法查看測驗結果。</p>
        <div className={styles.bottom}>
          <button type="button" className={styles.confirmClose} onClick={onCloseButtonClick}>確定關閉</button>
          <button type="button" className={styles.goBack} onClick={goBack}>返回上一頁</button>
        </div>
      </div>)
    } else if (goToOneLink) {
      return (<div className={styles.boxModal}>
        <div className={styles.controlHead}>
          <div className={styles.closeButton} onClick={onCloseButtonClick}>
            <Icon type="text" name="times" />
          </div>
        </div>
        <h1 className={styles.oneLinkTitle}>
          <img src={oneLinkSuccess} alt="成功" />
          派送成功</h1>
        <p className={styles.oneLinkDialog}>可至OneLink測驗列表查看詳情。</p>
        <div className={styles.bottom}>
          <a href={goToOneLinkLink} className={styles.oneLinkLink} target="_blank" rel="noopener noreferrer">前往OneLink  ❯</a>
        </div>
      </div>)
    } else {
      return (<div className={styles.boxModal}>
        <div className={styles.controlHead}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.closeButton} onClick={onCloseButtonClick}>
            <Icon type="text" name="times" />
          </div>
        </div>
        <div className={styles.content}>
          {content}
        </div>
        <div className={styles.bottom}>
          {bottom}
        </div>
      </div>)
    }
  }
  return (
    <div className={styles.paiquizMask}>
      <Draggable
        handle={`.${styles.controlHead}`}
        positionOffset={{ x: '-50%', y: '-50%' }}
      >
        {paiQuizContent()}
      </Draggable>
    </div>
  )
}


export default BoxModal;