import React, { useEffect, useRef, useState } from "react";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import styles from "./index.module.scss";
import "./content.scss";
import classnames from "classnames";
import AudioControlsButton from "components/common/AudioControlsButton";
import Repository from "repositories/Repository";
import { ScrollCoordinate, ScrollPositionButtonStateType,ScrollPositionType, ScrollLength} from "constants/scrollPositionType";
import { ScrollButtonStateBehavior,scrollPositionButtonBehavior } from "../ExtendedContent/ExtendedContentScrollPositionButton";
import { parseChineseOnly } from "util/zhuyin";


const { ExtendedResourceRepository } = Repository;

const AboutTheAuthor = ({ data, style }) => {
  const { contents, defaultActive } = data;
  const wordRef = useRef();
  const [{ bookId,books }] = useStore(StoreTypes.books);
  const [{ fontSize, scrollPosition }] = useStore(StoreTypes.extendedContent);
  const [{ tabIndex, html }, setState] = useSetState({
    tabIndex: defaultActive,
    html: "",
  });
  const [currentScrollLeft, setCurrentScrollLeft] = useState(0);
  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const elementaryHighGradeCheck =
    books[0].display_name.includes("國小國語5") ||
    books[0].display_name.includes("國小國語6");
  const targetScrollWidth = wordRef.current? wordRef.current.scrollWidth : 0

  useEffect(() => {
    const getHtml = async () => {
      const htmlJson = await ExtendedResourceRepository.getJSONContent({
        bookId,
        pathName: contents[tabIndex].word
          ? contents[tabIndex].word.split(".json")[0]
          : "",
      });
      setState({ html: htmlJson.html });
    };
    
    getHtml();
  }, [bookId, contents, setState, tabIndex]);

  useEffect(() => {
    // 小視窗內容上下頁分段
    switch (scrollPosition) {
      case ScrollPositionType.Next: {
        // console.log("---B類小視窗---AboutTheAuthor", wordRef);
        setLastScrollLeft(wordRef.current.scrollLeft);
        wordRef.current.scrollLeft -= wordRef.current.offsetWidth*ScrollLength;
        setCurrentScrollLeft(wordRef.current.scrollLeft);
        break;
      }
      case ScrollPositionType.Prev: {
        setLastScrollLeft(wordRef.current.scrollLeft);
        wordRef.current.scrollLeft += wordRef.current.offsetWidth*ScrollLength;
        setCurrentScrollLeft(wordRef.current.scrollLeft);
        break;
      }
      default: {
        scrollPositionButtonBehavior({currentScroll: currentScrollLeft, lastScroll: lastScrollLeft, coordinate: ScrollCoordinate.X })
        break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition]);

  useEffect(()=> { // 小視窗內容上下頁分段
    const timer = setTimeout(()=> {
      const updateScrollButtonState = () => {
        const offsetWidth = wordRef.current.offsetWidth
        const scrollWidth = wordRef.current.scrollWidth
        const scrollLeft = wordRef.current.scrollLeft
        const headCond = scrollLeft >= 0
        const bottomCond = (scrollWidth - offsetWidth + scrollLeft) < 10
        if (headCond) {
          ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head)
        }else if(bottomCond) {
          ScrollButtonStateBehavior(ScrollPositionButtonStateType.Bottom)
        } else {
          ScrollButtonStateBehavior(ScrollPositionButtonStateType.Middle)
        }
  
      }
      if (wordRef && wordRef.current) {
        wordRef.current.scrollLeft = 0
        ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head)
        
        wordRef.current.addEventListener("scroll", updateScrollButtonState, false)
      }
      return () => {
        wordRef.current.removeEventListener('scroll', updateScrollButtonState, false)
      }
    }, 200)
    return () => clearTimeout(timer)
  },[])
  
  // 文字排版，如稿紙徹底對齊文字和標點
  useEffect(() => {
    const ruby = wordRef.current.querySelector('ruby');
    const rubyHeight = ruby ? getComputedStyle(ruby).fontSize : null;
    const punctuation = wordRef.current.querySelectorAll('sign');
    punctuation.forEach(element => {
      element.style.display = 'inline-block';
      element.style.height = rubyHeight;
    });
  })

  return (
    <div className={classnames(styles.aboutTheAuthor, fontSize)}>
      {contents.length > 1 && (
        <div className={styles.tab}>
          {contents.map((list, index) => (
            <div
              className={classnames(styles.list, {
                [styles.active]: tabIndex === index,
              })}
              key={index}
              onClick={() => setState({ tabIndex: index })}
            >
              {list.tab}
            </div>
          ))}
        </div>
      )}
      <div className={styles.sound}>
        {contents[tabIndex].sound && (
          <div className={styles.radio}>
            <AudioControlsButton
              src={ExtendedResourceRepository.getDataSrc({
                bookId,
                pathName: contents[tabIndex].sound,
              })}
            />
          </div>
        )}
      </div>
      {contents[tabIndex].image && (
        <div className={styles.image}>
          <img
            src={`${ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: contents[tabIndex].image,
            })}`}
            alt=""
          />
        </div>
      )}
      <div className={styles.word} ref={wordRef}>
        {contents[tabIndex].word && (
          <div
            className={styles.word}
            dangerouslySetInnerHTML={{ __html: elementaryHighGradeCheck? parseChineseOnly(html, "aboutTheAuthor") : html }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default AboutTheAuthor;
